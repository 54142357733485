/* src/components/Message.css */
.message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: center;
    background-color: #2196F3; /* Blue */
    color: #fff;
  }
  
  .message.success {
    background-color: #4CAF50; /* Green */
  }
  
  .message.error {
    background-color: #f44336; /* Red */
  }
  
  .message.info {
    background-color: #c8e302; /* Blue */
  }
  