
/* ResetPasswordForm.css */
.form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  .requisitos-senha {
    margin-top: 20px;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 4px;
    list-style: none;
  }
  
  .mensagem-resposta {
    margin-top: 20px;
    text-align: center;
    /*background-color: #006C34 !important;*/
  }  
  
  .image-container {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    height: 200px; /* Define a altura total da tela (ou ajuste conforme necessário) */
  }

/* Garantir que o body e html ocupem toda a tela */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container-full {
  width: 100vw; /* Ocupar 100% da largura da viewport */
  height: 100vh; /* Ocupar 100% da altura da viewport */
  display: flex; /* Usado para centralizar conteúdo */
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  background-image: url('../assets/login.png'); /* Adicione a imagem de fundo */
  background-size: cover; /* Faz a imagem cobrir toda a área */
  background-position: center; /* Centraliza a imagem no meio */
  background-repeat: no-repeat; /* Evita a repetição da imagem */
  margin: 0; /* Remove espaçamentos externos */
  padding: 0; /* Remove espaçamentos internos */
  box-sizing: border-box; /* Inclui bordas no cálculo do tamanho */
}


.card {
  width: 100%; /* Aumenta a largura */
  max-width: 800px; /* Define um limite para não ultrapassar */
  height: auto; /* Mantém a altura flexível */
}

/* Sobrescrevendo os estilos padrão do btn-primary */
.btn-primary {
  background-color: #FD7807 !important; /* Cor de fundo laranja */
  border-color: #FD7807 !important; /* Cor da borda */
  color: white !important; /* Texto branco */
  border-radius: 50px !important; /* Bordas arredondadas */
  padding: 10px 20px !important; /* Espaçamento interno */
  font-weight: bold; /* Texto em negrito */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Efeito suave no hover */
}

/* Botão menor específico */
.btn-token {
  padding: 5px 10px !important; /* Botão menor */
  font-size: 14px !important; /* Tamanho menor do texto */
  border-radius: 30px !important; /* Bordas arredondadas menores */
}

/* Hover (quando o mouse está sobre o botão) */
.btn-primary:hover {
  background-color: #E06F05 !important; /* Cor mais escura para o hover */
  border-color: #E06F05 !important; /* Ajusta a cor da borda no hover */
}

/* Foco (quando o botão está focado ou clicado) */
.btn-primary:focus {
  outline: none !important; /* Remove o contorno padrão */
  box-shadow: 0 0 5px rgba(253, 120, 7, 0.5) !important; /* Adiciona um brilho */
}

.rounded-div {
  border-radius: 15px; /* Define o arredondamento dos cantos */
  border-left: 5px solid #FD7807; /* Define a borda na lateral esquerda */
  padding: 10px; /* Espaçamento interno */
  background-color: white; /* Cor de fundo da div */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Sombra leve para destaque */
  padding-bottom: 10px;
  font-size: 12px;
}

.form-control {
  font-size: 16px; /* Ajusta o tamanho do texto no campo */
}

.form-label {
  font-size: 14px; /* Ajusta o tamanho do texto do label */
}

.form-select {
  font-size: 14px; /* Define o tamanho da fonte para o select */
}

.form-select option {
  font-size: 14px; /* Define o tamanho da fonte para as opções */
}

.token{
  color: red;
  font-weight: bold;
}

p.error {   
  font-weight: bold;
  padding: 10px;
  border-radius: 7px !important; 
  text-align: center !important;
  color: #FF6178 !important;
  background-color: #F8D7DA !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #FF6178;
}

p.success { 
  font-weight: bold;  
  padding: 10px;
  border-radius: 7px !important;
  text-align: center !important;
  color: #006C34 !important;
  background-color: #D4EDDA !important;
  opacity: 0.7;
  border-left: 5px solid #006C34;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/*p.message.message {  
  font-weight: bold;  
  padding: 10px;
  border-radius: 7px !important;
  text-align: center !important;
  color: #6C757D !important;
  background-color: #D6D8D9 !important;
  opacity: 0.7;
  border-left: 5px solid #6C757D;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}*/

p#label{
  font-weight: bold;  
  color: #FF6178 !important;
}

p.message.message {  
  font-weight: bold;  
  padding: 10px;
  border-radius: 7px !important;
  text-align: center !important;
  color: #C69500 !important;
  background-color: #FFF3CD !important;
  opacity: 0.7;
  border-left: 5px solid #C69500;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* Esconde o ícone do Bootstrap apenas para o campo com ID "password-confirm" */
#password-confirm::-ms-reveal,
#password-confirm::-webkit-clear-button {
  display: none !important; /* Remove o ícone padrão */
}

/* Esconde o ícone do Bootstrap apenas para o campo com ID "password-confirm" */
#password::-ms-reveal,
#password::-webkit-clear-button {
  display: none !important; /* Remove o ícone padrão */
}





