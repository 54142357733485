/* index.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #06401e;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

input, button {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

button {
  background-color: #cc5b2d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #cc5b2d;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
